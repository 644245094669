





































import '@/declarations/vue-meta';
import { Component, Vue } from 'vue-property-decorator';
import { test } from '@/configReader/config';
import TestFooter from '@/components/Footer.vue';

@Component({
  metaInfo() {
    return {
      title: this.$store.getters.GET_CONFIG.thanks.body.seoTitle,
    };
  },
  components: {
    TestFooter,
  },
})
export default class Form extends Vue {
  get Sconfig(): test {
    return this.$store.getters.GET_CONFIG;
  }
}
